@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import '../node_modules/react-bootstrap-typeahead/css/Typeahead.min.css';
@import '../node_modules/react-toastify/dist/ReactToastify.css';
@import './assets/css/style.css';
@import './assets/css/responsive.css';

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #b3b1b18f, #b3b1b18c);
  z-index: 9999999;
}

#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#preloader #status .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #007bff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

#preloader #status .spinner .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.Toastify__toast-container--top-right {
  z-index: 999999 !important;
}
.pointer{
  cursor: pointer;
}

/* ----------- */
/* Ensure uniform highlighting color */
.react-datepicker__day--highlighted {
  background-color: #4caf50 !important; /* Use a uniform green background */
  color: white !important; /* Set the text color to white for better contrast */
  border-radius: 50%; /* Optional: make the highlighted days circular */
}

/* Optional: Hover effect for better UX */
.react-datepicker__day--highlighted:hover {
  background-color: #388e3c !important; /* Darker green on hover */
}
.date-input {
  font-size: 12px;
border:1px solid #ced4da;
  padding: 5px;
  border-radius: 3px; 
  outline: none; 
  width: 100%;
}