* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --blue-color: #96b8fa;
  --red-color: #ff9190;
  --green-color: #36e0b9;
  --orange-color:#F8B64C;
  --sky-color:#E7F1FF;

}
@font-face {
  font-family: roboto;
  src: url(/public/Roboto/Roboto-Regular.ttf);
}

body {
  /* font-family: "Poppins", sans-serif; */
  font-family: 'Lato', sans-serif;
  background: #f7f7ff;
}
.small-text,label {
  font-size: 12px;
  font-weight: 500;
}
.medium-text,label {
  font-size: 14px;
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.apply-leave-after-show input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: 0;
}
.select_on_pagination{
  border: none;
  outline: none;
  width: auto;
}

/* Firefox */
input[type=number]{
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

/* .sidebar-wrapper */
.sidebar-wrapper {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 58px;
  bottom: 0;
  left: 0;
  background: #fff;
  border-right: 0 solid #e4e4e4;
  z-index: 11;
  /* box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%) !important; */
  transition: all 0.3s ease-in;
  transform: translateX(-60%);
}
.button-extra-small{
  font-size: 8px !important;
}

.sidebar-active{
  transform: translateX(0%);

}

.metismenu .icon {
  transition: all 0.3s ease-in;
  margin-left: 140px;
}
/* .sidebar-wrapper:hover {
  transform: translateX(0%);
}
.sidebar-wrapper:hover .accordion-button::after {
  display: block;
}
*/
 .icon-active {
  margin-left: 0px;
}  
.dashboard-heading {
  display: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.dashboard-heading-active {
  display: contents;
  opacity: 1;
}
.invalid-feedback{
  font-size: 11px;
}

.page-wrapper-disactive{
  margin-left: 100px;
}
.page-wrapper-active{
  margin-left: 250px; 
}
.sidebar-wrapper .metismenu .list-group .list-group-item {
  padding: var(--bs-list-group-item-padding-y) 0;
}

.sidebar-wrapper .metismenu .list-group .list-group-item a {
  padding: 0;
}
.border-right{
   border-right: 2px solid #80808066;
}
.topbar {
  position: fixed;
  top: 0;
  left: 0px;
  right: 0;
  height: 60px;
  background: #fff;
  border-bottom: 1px solid rgb(228 228 228 / 0%);
  z-index: 10;
  -webkit-box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 0px 6px 0 rgb(206 206 238 / 54%);
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 0px 6px 0 rgb(206 206 238 / 54%);
}

.topbar .navbar {
  width: 100%;
  height: 60px;
  padding: 0 30px;
}

.mobile-search-icon {
  display: none;
}

.dropdown-large {
  position: relative;
}

.topbar .navbar .navbar-nav .nav-link {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  color: #252323;
  font-size: 22px;
}

.alert-count {
  position: absolute;
  top: 5px;
  left: 22px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  background: #f62718;
}

.search-bar-box {
  width: 45%;
  background-color: #efeffa;
}
.search-bar-box  input{
  background-color: transparent;
  border: none;
  padding-block: 8px;
}

.search-close {
  font-size: 24px;
  right: 10px;
  cursor: pointer;
  display: none;
}
.cursor-pointer{
  cursor: pointer;
}
.border-dashed{
  border: 1px  dotted rgb(156, 155, 155);
}

.mobile-search-icon {
  display: none;
}

.dropdown-large .dropdown-menu {
  width: 360px;
  border: 0;
  padding: 0 0;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

/* .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border: 0;
  border-radius: 10px;
  font-size: 14px;
} */

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.topbar .navbar .dropdown-menu {
  -webkit-animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
    animdropdown;
  animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
    animdropdown;
}

.app-box {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 26px;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  background-color: #f6f6f6;
}

.bg-gradient-cosmic {
  background: linear-gradient(to right, #8e2de2, #4a00e0) !important;
}

.app-title {
  font-size: 14px;
}

.bg-gradient-burning {
  background: linear-gradient(to right, #ff416c, #ff4b2b) !important;
}

.bg-gradient-lush {
  background: linear-gradient(to right, #56ab2f, #a8e063) !important;
}

.bg-gradient-kyoto {
  background: linear-gradient(to right, #f7971e, #ffd200) !important;
}

.bg-gradient-blues {
  background: linear-gradient(to right, #56ccf2, #2f80ed) !important;
}

.bg-gradient-moonlit {
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364) !important;
}

.dropdown-large .msg-header {
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #ededed;
  background-clip: border-box;
  background: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown-large .msg-header .msg-header-title {
  font-size: 15px;
  color: #1c1b1b;
  margin-bottom: 0;
  font-weight: 500;
}

.dropdown-large .msg-header .msg-header-clear {
  font-size: 12px;
  color: #585858;
  margin-bottom: 0;
}

.header-notifications-list {
  position: relative;
  height: 360px;
}

.dropdown-large .dropdown-menu .dropdown-item {
  padding: 0.5rem 1.3rem;
  border-bottom: 1px solid #ededed;
}

.bg-light-primary {
  background-color: rgb(13 110 253 / 0.11) !important;
}

.dropdown-large .notify {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 22px;
  text-align: center;
  border-radius: 50%;
  background-color: #f1f1f1;
  margin-right: 15px;
}

.dropdown-large .msg-name {
  font-size: 14px;
  margin-bottom: 0;
}

.dropdown-large .msg-time {
  font-size: 12px;
  margin-bottom: 0;
  color: #919191;
}

.header-message-list {
  position: relative;
  height: 360px;
}

.dropdown-large .user-online {
  position: relative;
}

.dropdown-large .msg-info {
  font-size: 13px;
  margin-bottom: 0;
}

.user-box {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--bs-border-color);
  border-right: 1px solid var(--bs-border-color);
  margin-left: 1rem;
  padding: 0.5rem 1rem;
}

.user-img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  padding: 0;
  object-fit: cover;
  border: 1px solid var(--bs-border-color);
}

.user-info p {
  font-size: 15px;
  /* font-weight: 500; */
  margin-bottom: 0;
}

.user-info span {
  font-size: 13px;
  color: var(--bs-secondary);
}

.user-box .dropdown-menu i {
  vertical-align: middle;
  margin-right: 10px;
}

.user-box .dropdown-menu a {
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 400;
}

.user-box .dropdown-toggle::after {
  display: none;
}

.mobile-toggle-menu {
  display: none;
  font-size: 26px;
  color: #404142;
  cursor: pointer;
}

.page-wrapper {
  height: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
  /* margin-left: 100px; */
  transition: all 0.3s ease-in;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-select {
  font-size: 0.7rem;
  font-weight: 400;
}
.input-group-text {
  font-size: 0.6rem;
  font-weight: 400;
}
.heading-font-company,
label {
  font-size: 0.8rem;

}

.form-control,
.form-select {
  border-radius: 2px !important;
}

.page-content {
  padding: 1.5rem 1.5rem 0.7rem 1.5rem;
}


.page-wrapper .page-content .card .card-body .card-title {
  text-transform: capitalize;
  margin-bottom: 1.5rem;
  font-weight: 500;

}

.topbar .navbar .navbar-nav .nav-link::after {
  display: none;
}

.topbar .navbar .dropdown-menu::after {
  content: "";
  width: 13px;
  height: 13px;
  background: #ffff;
  position: absolute;
  top: -6px;
  right: 16px;
  transform: rotate(45deg);
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

@-webkit-keyframes animdropdown {
  from {
    -webkit-transform: translate3d(0, 6px, 0);
    transform: translate3d(0, 6px, 0);
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes animdropdown {
  from {
    -webkit-transform: translate3d(0, 6px, 0);
    transform: translate3d(0, 6px, 0);
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.sidebar-header {
  height: 60px;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid var(--bs-border-color);
}

.logo-text {
  font-size: 22px;
  /* margin-left: 10px; */
  margin-bottom: 0;
  letter-spacing: 1px;
  color: #008cff;
}

.sidebar-wrapper .metismenu {
  /* background: 0 0; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  /* margin-top: 60px; */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100% - 60px);
}

.sidebar-wrapper .metismenu::-webkit-scrollbar {
  width: 4px;
}

.sidebar-wrapper .metismenu::-webkit-scrollbar-thumb {
  background: #dbe5ed;
  border-radius: 4px;
}

.sidebar-wrapper .metismenu::-webkit-scrollbar-track {
  background: #f9fafc;
}

.sidebar-wrapper .metismenu > li {
  list-style: none;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid var(--bs-border-color);
}

.sidebar-wrapper a {
  text-decoration: none;
  display: block;
  /* align-items: center; */
  /* justify-content: left; */
  font-weight: 500;
  font-size: 14px;
  color: var(--bs-dark);
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all 0.3s ease-out;
}

.sidebar-wrapper .metismenu .accordion-item .accordion-button {
  box-shadow: none;
  font-weight: 500;
  color: var(--bs-dark);
  font-size: 14px;
}

.sidebar-wrapper .metismenu .accordion .accordion-item .accordion-body {
  padding: 0 var(--bs-accordion-body-padding-x);
}

.sidebar-wrapper .metismenu .active {
  color: var(--bs-primary);
  border-right: 4px solid var(--bs-primary);
}

.accordion-button::after {
  scale: 80% !important;
}

.list-style-none {
  list-style: none;
}

.employee-tab .nav-tabs .nav-link {
  font-weight: 500;
}

/* .employee-form label {
  font-size: 14px;
  font-weight: 600;
} */

.app-table table thead {
  background: #f7f8fe;
}

.app-table .table > thead {
  vertical-align: middle;
}


.employee-card img {
  height: 5rem;
  width: 5rem;
}

.object-fit-cover {
  object-fit: cover;
}

.employee-card h5 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  width: 100%;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.app-table table thead tr th {
  font-weight: 600;
  color: var(--bs-secondary);
  text-transform: capitalize;
  font-size: 13px;
  white-space: nowrap;
}
.border-top-dash{
  border-top: 1px dashed #A5A5BA ;
}
.center-heading table thead tr th {
  font-weight: 600;
  color: var(--bs-secondary);
  text-transform: capitalize;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
}

.page-wrapper .page-content .app-table {
  border: 1px solid var(--bs-border-color);
}

.app-table table tbody tr td {
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  text-transform: capitalize;
  color: var(--bs-dark);
  font-weight: 500;
  white-space: nowrap;
}

.app-table table tbody tr td p {
  margin-bottom: 0;
  line-height: 1.5;
}

.app-breadcrumb h4 {
  text-transform: capitalize;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 0;
}

.app-breadcrumb .breadcrumb .breadcrumb-item a {
  text-decoration: none;
}

.app-breadcrumb .breadcrumb .breadcrumb-item {
  text-transform: capitalize;
}

.form-select option {
  text-transform: capitalize;
}

.form-select {
  text-transform: capitalize;
}
.form-check-label{
  color: black !important;
}

.form-label{
  color: #000000 !important;
}
.form-label,
.form-check-label {
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}

.table-responsive::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #dbe5ed;
  border-radius: 16px;
}

/* .table-action-btn ul {
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
} */

.page-content .card {
  border-radius: var(--bs-border-radius-xl);
  border: 0;
  margin: 0.8rem 0;

}

/*  */
.modal-body::-webkit-scrollbar {
  width: 4px;
}

.modal-body::-webkit-scrollbar-thumb {
  background: var(--bs-secondary);
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background: var(--bs-light);
}

.modal .modal-dialog .modal-content .modal-body .modal-table table tbody tr th {
  font-weight: 500;
  color: var(--bs-secondary);
  text-transform: capitalize;
  font-size: 14px;
  white-space: nowrap;
  background: #f7f8fe;
  width: 50%;
  padding: 0.4rem;
}
.modal-table table tbody tr th {
  font-size: 14px;
  background: rgba(243, 240, 240, 0.186);
}
.modal-table table tbody tr td {
  font-size: 14px;
}
.modal .modal-dialog .modal-content .modal-body .modal-table table tbody tr td {
  font-size: 14px;
  padding: 0.4rem;
  text-transform: capitalize;
  color: var(--bs-dark);
  font-weight: 500;
  width: 50%;
}

.font-small{
  font-size: 8px;
}
.font-weight-bold{
  font-size: large;
}
.font-weight-medium{
  font-size: medium;
}
.font-weight-small{
  font-size: small;
}

.ess-exit-application .modal-dialog {
  max-width: 550px;
}

.ctc-component {
  border: 1px solid var(--bs-border-color);
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  border-radius: var(--bs-border-radius-lg);
}

.ctc-component h6 {
  margin-bottom: 0;
  text-align: center;
  color: var(--bs-primary);
}

.arrear-emp-listing p:first-of-type {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 0;
}

.arrear-emp-listing p:not(:first-of-type) {
  color: var(--bs-secondary);
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
}

.page-wrapper .page-content .card .card-body hr {
  border-style: dashed;
}

.arrear .app-table table tbody tr td {
  border: 0;
}

.arrear .app-table table tbody tr td .form-control {
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + var(--bs-border-width) * 2);
  padding: 0.25rem 0.5rem;
}

.arrear-bottom ul {
  display: flex;
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

.arrear-bottom ul li p {
  font-weight: 500;
  margin-bottom: 0;
}

.sub-head ul {
  padding: 0;
  list-style: none;
  display: flex;
  margin-bottom: 0;
  flex-wrap: wrap;
}

.sub-head ul li {
  margin: 0 8px 4px 0;
}

.app-modal .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 18px;
  text-transform: capitalize;
}

.app-modal .modal-dialog .modal-content .modal-header {
  padding: 10px 1rem;
}

.app-modal .modal-dialog .modal-content .modal-footer {
  padding: 6px 1rem;
}

.table-action-btn .dropdown .dropdown-toggle::after {
  display: none;
}

.table-action-btn .dropdown .dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border: 0;
  border-radius: 10px;
  font-size: 12px;
}

.table-action-btn .dropdown .btn-sm {
  padding: 0 4px;
}

.table-action-btn .dropdown .dropdown-menu .dropdown-item {
  color: var(--bs-secondary);
  font-weight: 500;
}

.table-action-btn .dropdown .dropdown-menu .dropdown-item:active {
  color: var(--bs-light);
}

.no-record {
  text-align: center;
  color: var(--bs-secondary);
  font-weight: 600;
}

.text-none {
  text-transform: none;
}

.arrear .form-check {
  margin-bottom: 0;
}

.company-setting .nav-pills .nav-link {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 2px;
  width: 100%;
  text-align: left;

}
.nav-item .nav-link{
  padding-inline: 0.3rem !important;
  padding-block: 0.4rem !important;
}

.page-wrapper .page-content .card .card-body .input-group .input-group-text {
  border-radius: 2px 0 0 2px;
  font-weight: 500;
}

.border-dashed {
  border-style: dashed;
}
.mylable {
  font-size: 15px;
  font-weight: 500;
}
/* -------------login css--------------- */

.login-admin {
  /* background: url("../img/loginessbg.png"); */
  background-color: #dce8ff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
.login-ess {
  /* background: url("../img/loginadminbg.png"); */
  background-color: #dce8ff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
.card-bg {
  background-color: #96b8fa;
  padding: 20px;
}
.bg-login-input .form-control {
  background-color: #dce8ff;
  border-radius: 10px !important;
}
.btn-login-admin {
  border-radius: 10px;
  background: #0d43aa;
}

.bg-login-social {
  background-color: #dce8ff;
  color: #1877f2;
  border-radius: 10px;
}
.Sign-up-pointer {
  cursor: pointer;
  color: #ffc107;
}

.swapping-div {
  transition: transform 0.5s ease-in-out;
}

.left {
  transform: translateX(0%);
}
.left-move {
  transform: translateX(-180%);
}
.right {
  transform: translateX(0%);
}
.right-move {
  transform: translateX(53%);
}
.content {
  padding: 10px;
}
.select-company-bg {
  background-color: #dce8ff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
.company-heading {
  background-color: #dce8ff;
}
.company-btn {
  background-color: #dce8ff;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.5s ease;
}
.company-btn:hover > button > span {
  color: #ffff;
}
.company-btn:hover {
  background-color: #1877f2;
  color: #ffff;
  cursor: pointer;
  border-radius: 4px;
}
.company-card {
  background-color: #96b8fa;
 
  /* background-color: #dce8ff;
  color: #1877f2; */
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto; /* Centers the circle */
}
.icons-dashboard {
  height: 1.5em;
  width: 1.5em;
  color: #008cff;
}

#calender {
  border: none;
  outline: none;
  padding-right: 0px;
  max-width:120px; /* Space for the calendar image */
}

/* Style for the calendar image */
#calender::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  background-image: url(../../assets/img/calender.png) !important; 
  background-size: contain;
  right: 10px;
}
.pagination .page-item .page-link{
 padding-top: 1px;
 padding-bottom: 1px;
}

.input-width{
  display: inline-block !important;
  width: 130px !important;
}
.s-width{
  display: inline-block !important;
  width: 85px !important;
}
.ss-width{
  display: inline-block !important;
  width: 60px !important;
}
.sort-width{
  display: inline-block !important;
  width: 85px !important;
}
.modal-bottom-right {
  position: fixed !important;
  bottom: 0 !important;
  right: 0;
  margin-right: 20px; /* Adjust margin as needed */
  margin-bottom: 20px; /* Adjust margin as needed */
}

.circle-loan-advance p,.rounded-circle {
  display: none;
}
.circle-loan-advance p{
  display: none;
}
.circle-small {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divInput{
    height: 25px;
    width: 350px;
    border: 1px solid;
  /* overflow-x: auto; */
}

/* //coustomize multiselect css */
.rmsc .dropdown-heading .dropdown-heading-value{
  font-size: small !important;
}
.rmsc .dropdown-container{
  border-radius: 1.5px !important;
}
.rmsc .dropdown-heading{
height: 30px !important;
}

.rmdp-container input{
  padding: 15px 10px !important;
  width: 170px !important;
  font-size: 12px;
}

.date-picker-container {
  position: relative;
}

/* calendar css start */

.calender-container{
  border: 1px solid rgb(196, 205, 205);
  padding: 5px;
}


.calender-header{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  
  }
  
  .month-date{
    width: 140px;
    text-align: center;
    background-color: rgba(232, 239, 239, 0.967);
    border-radius: 5px ;
  }

  .week-days{
      display: grid;
      grid-template-columns: repeat(7,1fr);
      background-color: rgb(163, 242, 242);
  }
  .week-days span{
      border: 1px solid rgb(196, 205, 205);
      text-align: center;
      font-size: small;
  }
  .days-container{
      display: grid;
      grid-template-columns: repeat(7,1fr);
      border: 1px solid rgb(196, 205, 205);;
  
    
  }
  .days-container{
      text-align: center;
      border: 0.2px solid rgb(196, 205, 205);
      /* padding: 5px; */
  }
  .cell{
    height: 5rem;
      font-size: 12px;
      border: 0.2px solid rgb(196, 205, 205);
      display: flex;
     flex-direction: column;
     justify-content: space-between;
     padding: 5px;
     
  }

  .upper-p {
    display: flex;
    justify-content: space-between;
  }
  .shift-name{
    font-size: 10px;
    color: blue;
  }
  .shift-time{
    font-size: 10px;
    background-color: rgb(174, 244, 240);
    border-radius: 5px;
    padding-inline: 5px;
  }
/* calendar css  end*/